@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;400;500;700&display=swap");

.container {
  background-color: #011627;
  color: white;
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

body {
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  font-family: "Sora", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

.artGalleryContainer {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.birthdayReminderContainer {
  background-color: #effff1;
  padding-top: 50px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: inline-block;
}

.cardNavContainer {
  background-color: #f5f3f5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.pokemonContainer {
  background-color: rgb(228, 227, 227);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 20px;
}

.calculatorContainer {
  margin: 0;
  background-color: #323e33;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
}

.timesTablesContainer {
  background-color: #1a1d1a;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 5px;
}

.treeContainer {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
}

.AbletonMockContainer {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
}

.wordleContainer {
  background-color: black;
  color: #fffaff;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
