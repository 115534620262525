.button {
  background-color: #173753;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 26px;
  margin: 5px;
  border-radius: 5px;
  width: 75px;
  height: 75px;
  user-select: none;
  border: 2px solid black;
}

.button:active {
  background-color: #22537e;
}
