.pageContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
  margin: 0;
  padding: 10px;
  padding-left: 20px;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  background-color: #546d64b3;
  padding: 10px;
  padding-left: 20px;
  border-radius: 5px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 8px 24px;
}

.portrait img {
  object-fit: cover;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.blurb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 62rem;
}

.container .portrait {
  margin: 25px;
  z-index: 1;
}

.blurb h1 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 44px;
}

.blurb h3 {
  margin: 7px 20px;
  line-height: 26px;
}

.blurb h4 {
  margin-top: 7px;
}

.signature {
  font-style: italic;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
    width: 90%;
    padding: 5px 13em;
    margin: 0;
  }

  .blurb {
    width: 85vw;
  }
}
