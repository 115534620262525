* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #011627;
  border: none;
  width: 100%;
  height: 90px;
  margin: 20px 0;
  padding-bottom: 20px;
  padding-top: 20px;
}

.container h3 {
  margin: auto 4em;
  font-size: 24px;
  color: #99d19c;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
}

.container ul {
  display: flex;
  list-style: none;
}

.container ul li {
  margin: 5px 5px;
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 5px solid transparent;
}

.container ul li .link {
  color: white;
  font-size: 20px;
  text-decoration: none;
  user-select: none;
  padding: 2px 5px;
  transition: 0.2s;
  border-bottom: 5px solid transparent;
}

.container ul li .link:hover {
  border-bottom: 5px solid #99d19c;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  color: #99d19c;
}

.logoContent {
  position: absolute;
  width: 55px;
  height: 50px;
  background-color: #99d19c;
  font-weight: bold;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 5;
  border-radius: 3px;
  box-shadow: 0 0 0 5px black;
  transition: 0.2s;
}

.logoBackdrop {
  position: relative;
  width: 55px;
  height: 50px;
  background-color: #99d19c;
  right: -8px;
  top: 8px;
  box-shadow: 0 0 0 5px black;
  border-radius: 3px;
}

.logo {
  box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
}

.logo:hover {
  cursor: pointer;
}

.logoContent:hover {
  background-color: #b2efb5;
}

.icon {
  margin: 0;
  position: relative;
  padding: 0;
  transition: fill 0.2s;
}

.icon:hover {
  fill: #99d19c;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
    /* margin-left: 60px; */
    height: 160px;
  }

  .logo {
    margin: 30px;
  }
}
