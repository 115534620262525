.card {
  width: 60%;
  height: auto;
  background-color: #546d64b3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 8px 24px;
  color: white;
  font-weight: bold;
  margin-top: 1em;
}

.success {
  font-size: 30px;
  display: inline-block;
  color: black;
  text-align: center;
  background-color: #99d19c;
  border-radius: 25px;
  padding: 5px 20px;
  margin: 15px;
}

.contactText {
  font-size: 38px;
  display: inline-block;
  margin: 0 20px;
  margin-bottom: 10px;
}

form input,
textarea {
  width: 100%;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 5px;
  font-family: "Sora", sans-serif;
  border-radius: 5px;
  border: none;
}

form {
  width: 100%;
}

form textarea {
  resize: none;
}

form button {
  width: 100%;
  height: 2em;
  border: none;
  font-size: 28px;
  font-weight: bold;
  background-color: #99d19c;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  font-family: "Sora", sans-serif;
  border-radius: 5px;
}

form button:hover {
  cursor: pointer;
  background-color: #ade8b0;
}

@media (max-width: 700px) {
  .card {
    width: 90%;
  }
}
