.body {
  width: 400px;
  height: 500px;
  border: 3px solid black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #adbca5;
  border-radius: 2%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
