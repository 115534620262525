.container {
  width: 15em;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(235, 235, 235);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.types {
  display: flex;
}

.container h3 {
  text-align: center;
}

.container img {
  text-align: center;
}

.types div {
  margin: 5px;
  padding: 3px 12px 5px 12px;
  background-color: rgb(253, 171, 18);
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  user-select: none;
}
