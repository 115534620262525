.container {
  width: 100%;
  height: 90%;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -15px;
}
