.projContainer {
  display: flex;
  justify-content: center;
  width: 80%;
  flex-wrap: wrap;
  padding-bottom: 50px;
  text-align: center;
}

.projContainer img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.1s;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 8px 24px; */
}

.cardContainer {
  position: relative;
  width: 300px;
  height: 295px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 5px solid transparent;
  transition: 0.2s;
  margin: 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 8px 24px;
}

.cardContainer:hover img {
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 8px 24px; */
  opacity: 0.5;
}

.cardContainer:hover .btn {
  visibility: visible;
}

.cardContainer:hover .title {
  visibility: visible;
}

.title {
  width: 250px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 22px;
  margin: 10px;
  font-weight: bold;
  visibility: hidden;
}

.btn {
  position: absolute;
  bottom: 4em;
  padding: 5px 10px;
  background-color: #99d19c;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  margin: 5px;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  visibility: hidden;
}

.btn:hover {
  background-color: #c5f1c7;
}
