.tables {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tableBox {
  width: 150px;
  margin: 5px;
  font-size: 22px;
  border-radius: 5px;
  padding: 10px;
  background-color: #446e65;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: 10px;
  line-height: 28px;
  border: 1px solid rgb(17, 17, 17);
}

.row {
  text-align: center;
}

.modal {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 2s;
}

/* Modal box */
.modalContent {
  display: flex;
  background-color: #2d4c46;
  margin: 1% auto;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
  width: 70%;
  border-radius: 5px;
  color: white;
  transition: all 2s;
}

.out {
  display: none;
}

/* close btn */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: auto;
  margin-bottom: auto;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
