.card {
  margin: 0 auto 0 auto;
  width: 80%;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.display-card {
  width: 100%;
  height: 9rem;
  margin: 0 auto 15px auto;
  background-color: #2d4c46;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.inputAndDisplay {
  width: 70%;
}

.table-buttons {
  width: 70%;
  background-color: #2d4c46;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.table-buttons .row {
  display: flex;
  justify-content: space-evenly;
}

.table-buttons button {
  width: 50px;
  height: 50px;
  margin: 10px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 3px solid black;
  border-radius: 5px;
  user-select: none;
  font-size: 24px;
  font-weight: bold;
}

button {
  background-color: #fff;
}

.table-buttons button:hover {
  cursor: pointer;
}

.number-active {
  background-color: #a7c957;
}

/* Form styles */
.form-box {
  width: 100%;
  background-color: #2d4c46;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  padding: 10px;
  padding-right: 18px;
  align-self: center;
}

.form-box input {
  width: 100%;
  height: 8rem;
  text-align: center;
  font-size: 64px;
  caret-color: transparent;
  background-color: #e0ddcf;
  border: none;
  border-radius: 5px;
}

.form-box input:focus {
  background-color: #e0ddcf;
}

/* Display styles */

.question {
  font-size: 64px;
  color: #e0ddcf;
  text-align: center;
}

/* Feedback styles */
.feedback {
  height: 100%;
  font-size: 48px;
  color: #e0ddcf;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.correct {
  background-color: rgb(25, 125, 25);
}

.incorrect {
  background-color: rgb(200, 25, 25);
}

.correct-answer {
  font-size: 24px;
}

.initial-feedback {
  font-size: 18px;
  color: white;
  text-align: center;
}

@media (max-width: 700px) {
  .table-buttons {
    width: 100%;
  }

  .display-card {
    width: 100%;
  }

  .inputAndDisplay {
    width: 100%;
  }
}
